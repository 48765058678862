import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC } from "react";
import { useToggleFavourite } from "@/utils/queries/hooks/useToggleFavourite";
import { useStore } from "@/store/index";
import { checkRating } from "@/utils/common";
import styles from "../../../../styles/home/Home.CardTour.module.scss";
import userInterfaceSvg from "../../../../utils/svg-icon/userInterface";

const HeadOverlay: FC<{
    id: string;
    rating: number;
    isSmall: boolean;
    onClick: () => void;
}> = ({ id, rating, isSmall, onClick }) => {
    const { Heart, StarFilled } = userInterfaceSvg;
    const userDetail = useStore(state => state.userState.userDetail);
    // eslint-disable-next-line no-underscore-dangle
    const authType = useStore(state => state.userState.userDetail._id);
    const { mutate: toggle } = useToggleFavourite();

    const toggleWishlist = (_id: string) => {
        toggle(_id);
    };

    const checkedRating = checkRating(rating);

    return (
        <div
            className={`${styles.customMask} mask d-flex flex-column justify-content-center align-content-center`}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
        >
            <div
                className={` d-flex flex-row ${
                    isSmall ? "pt-5" : "pt-3"
                }  justify-content-${
                    checkedRating ? "between" : "end"
                } align-content-center align-items-center mx-3`}
            >
                {checkedRating && (
                    <div className={styles.ratingTag}>
                        <StarFilled
                            className={`${styles.colorSvg}`}
                            width="15"
                            height="15"
                            viewBox="0 0 25 25"
                        />
                        <p
                            className={`${
                                isSmall ? styles.captionSmall : styles.caption
                            } m-0 fw-bold`}
                        >
                            <span>{Number(rating)}</span>
                        </p>
                    </div>
                )}
                {authType && (
                    <MDBBtn
                        className={`${
                            userDetail.favouriteTours?.includes(id)
                                ? styles.buttonLikePressed
                                : styles.buttonLike
                        } d-flex justify-content-center`}
                        tag="a"
                        floating
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleWishlist(id);
                        }}
                    >
                        <Heart className="align-self-center" />
                    </MDBBtn>
                )}
            </div>
            <MDBRow
                className="d-flex justify-content-center p-5 pt-0 flex-fill"
                lg={10}
            >
                <MDBCol
                    className="d-flex justify-content-center align-content-center p-0"
                    lg={isSmall ? 12 : 8}
                >
                    <MDBBtn
                        className={`${
                            isSmall ? styles.textButtonSmall : styles.textButton
                        } ${styles.buttonDetails} my-auto mx-auto shadow-0`}
                        color="danger"
                        onClick={onClick}
                    >
                        View detail
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        </div>
    );
};

export default HeadOverlay;
