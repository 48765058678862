import { MDBCard } from "mdb-react-ui-kit";
import { FC, useMemo, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { useIsClient } from "@/utils/hooks/useIsClient";
import { getDestinationsSize } from "@/utils/common";
import { useStore } from "@/store/index";
import { useIsSM } from "@/utils/hooks/useWindowSizes";
import styles from "../../../../styles/home/Home.CardTour.module.scss";
import HeadOverlay from "./HeadOverlay";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";
import { slugify } from "../../../../utils/searchUtils";
import { ToursEntity } from "../../../../models/home";

const CardTour: FC<{
    content: ToursEntity;
    isSmall?: boolean;
    className?: string;
    classNameBody?: string;
}> = ({ content, isSmall, className = "", classNameBody = "" }) => {
    const [error, setError] = useState(false);
    const router = useRouter();
    const isClient = useIsClient();

    /* eslint-disable radix */
    const iOSVersion = useMemo(() => {
        if (!isClient) return null;
        const ua = navigator.userAgent;
        if (ua && /(iPhone|iPod|iPad)/i.test(ua)) {
            const version = ua
                .match(/OS [\d_]+/i)?.[0]
                .slice(3)
                .split("_")
                .find(n => parseInt(n));
            return version ? Number(version) : null;
        }
        return null;
    }, [isClient]);
    /* eslint-enable */

    const [hover, setHover] = useState<boolean>(false);
    // eslint-disable-next-line no-underscore-dangle
    const userId = useStore(state => state.userState.userDetail._id);

    const isSM = useIsSM();

    const handleClickText = () => {
        router.push({
            pathname: "/detail/[slug]",
            query: {
                slug: slugify(content.title ?? "Title"),
            },
        });
    };

    const handleClick = () => {
        if (isSM) {
            setHover(!hover);
        }
        if (userId) {
            if (hover) {
                handleClickText();
            }
        } else {
            handleClickText();
        }
    };

    /* eslint-disable no-underscore-dangle */

    return (
        <MDBCard
            className={`${
                isSmall ? styles.cardBodySmall : styles.cardBody
            } ${className}`}
            // onClick={handleClick}
        >
            <div
                className={`${styles.ripple} bg-image hover-overlay`}
                onMouseEnter={() => {
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
            >
                <div
                    className={`${
                        iOSVersion && iOSVersion <= 14
                            ? styles.imageContainer
                            : "h-100"
                    }`}
                    onClick={handleClick}
                >
                    <Image
                        className="object-cover h-100"
                        src={
                            error
                                ? "/images/tours/across-italy-reduced.jpg"
                                : content.image
                        }
                        alt="avatar-image"
                        fill
                        onError={() => {
                            setError(true);
                        }}
                    />
                </div>
                {hover && (
                    <HeadOverlay
                        id={content._id}
                        rating={content.rating}
                        isSmall={isSmall!}
                        onClick={handleClick}
                    />
                )}
            </div>
            <div onClick={handleClickText}>
                <CardBody
                    title={content.title ?? "Title"}
                    duration={content.duration}
                    nightDuration={content.nightDuration}
                    destinationCount={getDestinationsSize(
                        content?.destinations
                    )}
                    isSmall={isSmall!}
                    className={classNameBody}
                />
                <hr className="mx-3" />
                <CardFooter
                    className="p-3"
                    price={
                        !content.startingPrice
                            ? +content.price
                            : +content.startingPrice
                    }
                    rating={content.rating}
                    reviewsCount={content.reviews}
                    isSmall={isSmall!}
                />
            </div>
        </MDBCard>
    );
    /* eslint-enable no-underscore-dangle */
};

CardTour.defaultProps = {
    isSmall: false,
    className: "",
};

export default CardTour;
